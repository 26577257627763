@import url(https://fonts.googleapis.com/css?family=Product+Sans);
@import url(https://fonts.googleapis.com/css?family=Material+Icons);
* {
  margin: 0;
  padding: 0;
  font-family: "Product Sans";
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

:root {
  --text-primary-color: #313131;
  --text-secondary-color: rgba(0, 0, 0, 0.64);
  --highlight-color: #fca32b;
  --primary-color: #fca32b;
  --accent-color: #d82b7e;
  --shadow-color: rgba(0, 0, 0, 0.08);
  --shadow-color-darker: rgba(0, 0, 0, 0.16);
  --blueish-lightgray: #f0f0f5;
}

.App {
  background-color: #f5f5f9;
  min-height: 100vh;
}
