:root {
  --text-primary-color: #313131;
  --text-secondary-color: rgba(0, 0, 0, 0.64);
  --highlight-color: #fca32b;
  --primary-color: #fca32b;
  --accent-color: #d82b7e;
  --shadow-color: rgba(0, 0, 0, 0.08);
  --shadow-color-darker: rgba(0, 0, 0, 0.16);
  --blueish-lightgray: #f0f0f5;
}

.App {
  background-color: #f5f5f9;
  min-height: 100vh;
}